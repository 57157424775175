<template>
  <defaultFieldTypes
    :field="field"
    :fieldAttributes="{...fieldAttributes,options: manegers}"
    v-on="$listeners"
  :value="value"></defaultFieldTypes>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["fieldAttributes", "result", "field","value"],
  data() {
    return { manegers: this.result.additional.managersArray };
  },
  watch: {
    "result.valueLine.c_organisation": function (value) {
      console.log("changed", value);

      let activeProbeOrganisationValue = value;
      let caller = this;
      this.frameworkAxiosRequest({
        method: 'post',
        url: this.result.tableUrl,
        data: {
          id: activeProbeOrganisationValue,
          requestType: 'ajax',
          function: 'getOrganisationManager',
        },
      })
          .then(function (response) {
          caller.manegers = response.data.result.json.managersArray;
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    },
  },
};
</script>